body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.frow {
  display: flex;
  flex-direction: row;
}
.fcol {
  display: flex;
  flex-direction: column;
}
.jc {
  justify-content: center;
}
.ac {
  align-items: center;
}
.f1 {
  flex: 1;
}
.f2 {
  flex: 2;
}
.f3 {
  flex: 3;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.full-size {
  width: 100%;
  height: 100%;
}
.buttonAsText {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: inherit;
  font: inherit;
  text-align: left;
  text-decoration: none;
  display: inline;
  appearance: none;
}
.buttonAsText:hover {
  text-decoration: underline;
}
.active {
  color: #00A1ED;
}
.disabled {
  color: #DEE0E4;
}
.mgr {
  margin-right: 12px;
}
.mgr4 {
  margin-right: 4px;
}
.mgl {
  margin-left: 12px;
}
.mgb {
  margin-bottom: 12px;
}
.mgb0 {
  margin-bottom: 0px !important;
}
.mgb8 {
  margin-bottom: 8px;
}
.circle {
  border-radius: 100% !important;
}
.clickable {
  cursor: pointer;
}
.label {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  padding: 2px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  text-transform: capitalize;
}
.label-green {
  color: rgba(31, 109, 48, 1);
  background-color: rgba(214, 240, 221, 1);
  border-color: rgba(51, 182, 80, 1);
}
.label-red {
  color: rgba(133, 26, 0, 1);
  background-color: rgba(249, 213, 205, 1);
  border-color: rgba(222, 44, 0, 1);
}
.separator {
  border-bottom: 1px solid #EEF0F2;
  margin: 12px 2px;
  width: 100%;
}
.header {
  flex-basis: 48px;
  align-items: center;
  padding: 8px 12px;
  background-color: #F6F7F8;
  margin-bottom: 12px;
}
.main-page {
  width: 1200px;
  margin: 0 auto;
  background-color: #f1f2f3;
  height: 100vh;
  overflow-y: auto;
}
.pos-rel {
  position: relative;
}
.y-scroll {
  overflow-y: auto;
}