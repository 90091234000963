
.flyout-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: none;
  &.visible {
    display: block;
  }
}
.flyout-container {
  position: absolute;
  top: 0;
  right: -50%; /* Initially off-screen */
  width: 50%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.5s ease-in-out; /* Transition for smooth appearing */
  z-index: 100;
  &.visible {
    right: 0; /* Visible state, appearing from the right */
  }

  .toggle-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
  }

  .flyout-content {
    padding: 20px;
  }
}
