
.errored-border {
  border-color: #dc3545 !important;
}
.error-icon {
  color: #851A00;
  position: absolute;
  right: 0px;
  top: 1px; 
  bottom: 1px;
  margin: 1px;
  background-color: #F9D5CD;
  border-color:#DE2C00;
  cursor: pointer;
  padding: 4px 4px 4px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.numeric-error-icon {
  color: #851A00;
  position: absolute;
  right: 0px;
  top: 1px; 
  bottom: 1px;
  margin: 1px;
  background-color: #F9D5CD;
  border-color:#DE2C00;
  cursor: pointer;
  padding: 4px 4px 4px 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}